/// /////////////////////接口请求，具体对应和参数参考文档////////////////////
import http from "@/utils/http";
export const uploadI = "/uploadI";
class Api {
  /*
   * 字典
   */
  getDics = () => {
    return http.post("/admin/ser/dics");
  };

  /**
   * java字典
   */
  getConf = () => {
    return http.post("/guest/applet/conf");
  };
  /*
   * 登录 - 获取令牌
   */
  getToken = (data: any) => {
    return http.post("/admin/login/get-token-m", data);
  };
  /*
   * 统一登录
   */
  TYLOGIN = (data: any) => {
    return http.post("/admin/login/get-token-t", data);
  };

  /*
   * 登出 - 销毁令牌
   */
  destroyToken = () => {
    return http.post("/admin/login/destroy");
  };

  /*
   * 通用请求
   */
  post = (code: string, data?: any) => {
    return http.post("/admin/ser/api/" + code, data, {
      headers: { "Content-Type": "application/json" },
    });
  };
  postU = (code: string, data?: any) => {
    return http.post("/admin/ser/api/" + code, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };
  postUP = (code: string, fileName: any, data?: any) => {
    return http.post("/admin/ser/api/" + code + "?softLink=" + fileName, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  /**
   * 获取验证码
   * @param code
   * @param data
   * @returns
   */
  getVerCode = (data: any) => {
    return http.post("admin/login/send-verifycode", data);
  };

  /*
   * 通用请求
   */
  get = (code: string, data?: any) => {
    return http.get("/admin/ser/api/" + code, data);
  };

  getN = (code: string) => {
    return http.get("/admin/ser/api/" + code, { responseType: "blob" });
  };

  /*
   * 获取菜单
   */
  menus = () => {
    return http.post("/admin/ser/menus");
  };

  /*
   * 权限配置
   */
  auth = (data: any) => {
    return http.post("/admin/ser/auth", data);
  };
}
export default new Api();
