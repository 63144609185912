



import { Component, Vue } from "vue-property-decorator";
import store from "@/store";

import api from "@/api";
@Component({
  name: "PageAutologin",
})
export default class extends Vue {
  created() {
    const loading = this.$loading({
      lock: true,
      text: "自动登录中",
      spinner: "el-icon-loading",
      background: "rgba(255, 255, 255, 0.7)",
    });
    let token = this.$route.query.token as string;
    if (!token) {
      this.$router.replace({ path: "/" });
      return;
    }

    api
      .TYLOGIN({
        token: token,
      })
      .then((data) => {
        console.log(data);
        loading.close();
        if (data.sys.length === 0) {
          this.$alert("暂无权限，请联系管理员分配权限！", "系统提示");
          return;
        }
        store.dispatch("tags/NameView", data.user.username);
        api.getDics().then((res) => {
          store.dispatch("system/addConfig", res);
        });
        store.dispatch("user/Login", data);
        this.$router.push("/home");
      });
  }
}
