















































































































































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import api from "@/api";

@Component({
    name: "approalCard",
    components: {}
})

export default class extends Vue {
    tableData2 = [];
    total2 = 0;


    printTest = {
        id: 'printArea',
        popTitle: '打印', // 打印配置页上方标题
        extraHead: '', //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
        preview: '', // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
        previewTitle: '', // 打印预览的标题（开启预览模式后出现）,
        previewPrintBtnLabel: '', // 打印预览的标题的下方按钮文本，点击可进入打印（开启预览模式后出现）
        zIndex: '', // 预览的窗口的z-index，默认是 20002（此值要高一些，这涉及到预览模式是否显示在最上面）
        previewBeforeOpenCallback() {}, //预览窗口打开之前的callback（开启预览模式调用）
        previewOpenCallback() {}, // 预览窗口打开之后的callback（开启预览模式调用）
        beforeOpenCallback() {}, // 开启打印前的回调事件
        openCallback() {}, // 调用打印之后的回调事件
        closeCallback() {}, //关闭打印的回调事件（无法确定点击的是确认还是取消）
        url: '',
        standard: '',
        extraCss: '',
}

    // 提现明细
    detailDialog = false
    search2: any = {
        examineNumber: '',
        pagination: {
            page: 0, //页码，从0开始
            pageSize: 20, //每页数量
        },
    };
    toDetail(search2 = false) {
        let apiV = this.from == 'CK' ? 'J27901' : 'J27120'
        if (search2) {
            this.search2.pagination = {
                pageSize: 20,
                page: 0,
            };
        }
        api
            .post(apiV, this.search2)
            .then(res => {
                this.tableData2 = res.list
                this.total2 = res.total
                this.detailDialog = true
            })

    }

    form: any = {}

    examineNumber = ''
    getDetail() {
        api
            .post('J27164', {examineNumber: this.examineNumber})
            .then(res => {
                this.form = res
            })
    }

    // 跳转
    toJifen(userId: any) {
        this.detailDialog = false
        this.$router.push({
            name: 'applet_integral_detail',
            query: {
                userId: userId,
                from: this.from
            }
        })
    }

    // 获取权限
    auth: any = {}
    showAuth() {
        api
            .auth({
                group: "",
                userid: this.$store.state.user.user.id,
            })
            .then((data) => {
                let qz = [];
                qz = data.filter((item:any) => { return item['code'] == "applet"})[0]["list"].filter((item: any) => { return item['code'] == 'menu_a_3' })[0]["list"].filter((item: any) => { return item["code"] == 'applet_integral_apply' })[0]["list"]
                console.log('11', qz);
                this.auth.cwspQx = qz.filter((item: any) => { return item["code"] == 'J27165' })[0]['checked']
                this.auth.zjQx = qz.filter((item: any) => { return item["code"] == 'J27166' })[0]['checked']
                this.auth.cnQx = qz.filter((item: any) => { return item["code"] == 'J27167' })[0]['checked']

                this.getDetail()
            });
    }

    // 财务初审
    shenhe(msg: any, val: any) {
        let code = val == 1 ? 'J27165' : (val == 2 ? 'J27166' : (val == 3 ? 'J27167' : ''))
        api
            .post(code, {examineNumber: this.examineNumber, msg: msg, ret:1})
            .then(res => {
                if(res) {
                    this.$message.success('操作成功')
                }
                this.getDetail()
            })
    }

    code = ''
    from = ''
    activated() {
        this.from = ''
        let {examineNumber, code, cz, from} = this.$route.query
        this.examineNumber = examineNumber as string
        this.search2.examineNumber = examineNumber as string
        this.code = code as string
        if(from) {
            this.from = from as string
        }
        this.showAuth()
    }

}
