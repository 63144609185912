













































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";

@Component({
  components: {
  }
})
export default class AUser extends Vue {
  // 表格数据
  tableData = [];
  tableData2 = [];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  total2 = 0;
  // 搜索条件
  search: any = {
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  protected changePage(page: number) {
    this.search.pagination.page = page - 1;
    this.searchTable();
  }
  // 搜索 search:true 重置翻页
  apiCode = 'J27163'
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post(this.apiCode, this.search)
      .then(res => {
        this.total = res.total;
        this.tableData = res.list;
      }).finally(() => {
      this.loading = false;
    })
  }

  // 跳转
  toJifen(userId: any) {
    this.detailDialog = false
    this.$router.push({
      name: 'applet_integral_detail',
      query: {
        userId: userId
      }
    })
  }

  actName = ''
  tab(e:any) {
    console.log(e.name);
    this.apiCode = e.name
    this.searchTable()
  }

  // 获取权限
  auth: any = {}
  showAuth() {
    api
        .auth({
          group: "",
          userid: this.$store.state.user.user.id,
        })
        .then((data) => {
          let qz = [];
          qz = data.filter((item:any) => { return item['code'] == "applet"})[0]["list"].filter((item: any) => { return item['code'] == 'menu_a_3' })[0]["list"].filter((item: any) => { return item["code"] == 'applet_integral_apply' })[0]["list"]
          this.auth.authList = qz.filter((item: any) => { return (item["code"] == 'J27163' || item["code"] == 'J27168' ||item["code"] == 'J27169' || item["code"] == 'J27170') })
          this.auth.authQx = qz.filter((item: any) => { return (item["code"] == 'J27165' || item["code"] == 'J27166' ||item["code"] == 'J27167') })
          let cwspQx = qz.filter((item: any) => { return item["code"] == 'J27165' })[0]['checked']
          let zjQx = qz.filter((item: any) => { return item["code"] == 'J27166' })[0]['checked']
          let cnQx = qz.filter((item: any) => { return item["code"] == 'J27167' })[0]['checked']

          this.auth.authList.forEach((item: any) => {
            this.auth.authQx.forEach((itx: any) => {
              if(item['code'] == 'J27163') {
                item['title'] = '全部'
                item['jiedian'] = '-1'
              } else if(item['code'] == 'J27168') {
                item['title'] = '财务审批'
                item['jiedian'] = '1'
                item['Qxchecked'] = cwspQx
              } else if(item['code'] == 'J27169') {
                item['title'] = '财务总监审批'
                item['jiedian'] = '2'
                item['Qxchecked'] = zjQx

              } else if (item['code'] == 'J27170') {
                item['title'] = '出纳审批'
                item['jiedian'] = '3'
                item['Qxchecked'] = cnQx
              } else {
                item['jiedian'] = '4'
              }
            })

          })
          this.actName = this.auth.authList[0]['code']
          this.apiCode = this.auth.authList[0]['code']
          this.searchTable();
        });
  }

  // 审批查看
  toApproal(row: any, val: any, code: any) {
    if(row.examineChannelType == 0) {
      this.$router.push({
        name: 'approal',
        query: {
          cz: val,
          code: code,
          examineNumber: row.examineNumber
        }
      })
    } else if(row.examineChannelType == 1) {
      this.$router.push({
        name: 'approal',
        query: {
          cz: val,
          code: code,
          examineNumber: row.examineNumber,
          from: 'CK'
        }
      })
    }

  }

  // 提现明细
  detailDialog = false
  search2: any = {
    examineNumber: '',
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  toDetail(row: any, search2 = false) {
    let apiV = row.examineChannelType == 0 ? 'J27120' : 'J27901'
    this.search2.examineNumber = row.examineNumber
    if (search2) {
      this.search2.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post(apiV, this.search2)
      .then(res => {
        this.tableData2 = res.list
        this.total2 = res.total
        this.detailDialog = true
      })

  }

  activated() {
    this.showAuth()
  }
}
